<template>
    <div class="wage-services legal-services company-formation">
        <div class="container">
            <div class="banner-static-services">
                <h4>
                    {{ $t('Welcome to the Wages Protection System service in the Kingdom of Saudi Arabia') }}
                </h4>
                <p>
                    {{ $t('Welcome to the Wages desc') }}
                </p>
            </div>
            <Contact />

            <div class="features">
                <div class="title">
                    {{ $t('Advantages of the wage protection system with completion') }}
                </div>
                <p class="w8">
                   {{ $t('advantge wage desc') }}
                </p>
                <div class="row mt-5">
                    <div class="col-md-4 mb-3">
                        <div class="item">
                            <div class="title">{{ $t('Ensuring financial security') }}</div>
                            <p>{{ $t('Ensuring financial security desc') }}</p>
                        </div>
                    </div>
                    <div class="col-md-4 mb-3">
                        <div class="item">
                            <div class="title">{{ $t('Transparent mechanisms for estimation') }}</div>
                            <p>{{ $t('Transparent mechanisms for estimation desc') }}</p>
                        </div>
                    </div>
                    <div class="col-md-4 mb-3">
                        <div class="item">
                            <div class="title">{{ $t('Labor law') }}</div>
                            <p>{{ $t('We seek to improve wage standards by combating economic transformations and ensuring financial justice among all workers') }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="banner-2">
                <div class="row">
                    <div class="col-md-6 mb-3">
                        <img class="w-100" src="@/assets/img/services-page/wage-banner-2.png" alt="">
                    </div>
                    <div class="col-md-6" style="display: grid;place-content: center;">
                        <div class="title">
                            {{ $t('Wage protection') }}
                            <div style="font-size: 16px;"> {{ $t('In the Kingdom of Saudi Arabia') }}</div>
                        </div>
                        <p>
                           {{ $t('Wage protection desc') }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="how-help-you">
                <div class="title">
                    {{ $t('Wage Protection System Services with Etmaam') }}
                </div>
              <p>
                {{ $t('Comprehensive Solutions for Managing the Wage Protection System') }}
              </p>
              <div class="row mt-5 d-flex justify-content-center">
                <div class="col-md-2 flex-grow-1 d-flex">
                        <div class="item">
                            <div class="title">{{ $t('Coordination with Banks') }}</div>
                            <p class="multiline-text">
                                {{ $t('Open bank accounts for institutions and companies.') }}
                            </p>
                        </div>
                    </div>
                <div class="col-md-2 flex-grow-1 d-flex">
                        <div class="item">
                            <div class="title">{{ $t('Employee Account Management') }}</div>
                            <p class="multiline-text">
                               {{ $t('Open bank accounts for employees and request payroll cards.') }}
                            </p>
                        </div>
                    </div>
                <div class="col-md-2 flex-grow-1 d-flex">
                        <div class="item">
                            <div class="title">{{ $t('Uploading Salary Files') }}</div>
                            <p class="multiline-text">
                                {{ $t('Upload files to bank systems and the Ministry of Human Resources, automatically detecting errors.') }}
                            </p>
                        </div>
                    </div>
                <div class="col-md-2 flex-grow-1 d-flex">
                        <div class="item">
                            <div class="title">{{ $t('Auditing and Inspection') }}</div>
                            <p class="multiline-text">
                                {{ $t('Audit salary files to avoid violations and compare data with the General Organization for Social Insurance.') }}
                            </p>
                        </div>
                    </div>
                <div class="col-md-2 flex-grow-1 d-flex">
                    <div class="item">
                      <div class="title">{{ $t('Support and Training') }}</div>
                      <p class="multiline-text">
                        {{ $t('Train clients on using the system and provide ongoing support to ensure efficient operations.') }}
                      </p>
                    </div>
                  </div>
                </div>
            </div>
            <div class="steps-termination">
                <div class="row">
                    <div class="col-md-4 mb-3 info" >
                        <h3>{{ $t('procedures') }} </h3>
                        <h2>{{ $t('Wage protection') }}</h2>
                    </div>
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-md-6 mb-3">
                                <div class="item d-flex align-items-center">
                                    <img src="@/assets/img/services-page/termination-5.svg" alt="">
                                    <div>
                                        <h4>{{ $t('Planning') }}</h4>
                                        <p>{{ $t('To carry out financial operations to complete your work in an integrated manner') }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 mb-3 d-flex align-items-center">
                                <div class="item d-flex align-items-center">
                                    <img src="@/assets/img/services-page/termination-2.svg" alt="">
                                    <div>
                                        <h4>{{ $t('Register the facility on the Madad platform') }}</h4>
                                        <p>{{ $t('Determine the steps necessary to achieve the desired goal.') }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 mb-3 d-flex align-items-center">
                                <div class="item d-flex align-items-center">
                                    <img src="@/assets/img/services-page/termination-6.svg" alt="">
                                    <div>
                                        <h4>{{ $t('Update data periodically') }}</h4>
                                        <p>{{ $t('Determine the idea through market analysis and develop a business plan.') }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 mb-3 d-flex align-items-center">
                                <div class="item d-flex align-items-center">
                                    <img src="@/assets/img/services-page/termination-7.svg" alt="">
                                    <div>
                                        <h4>{{ $t('Registration in the social insurance system') }}</h4>
                                        <p>{{ $t('Determine the idea through market analysis and develop a business plan') }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
    components: {
        Contact: defineAsyncComponent(() => import('./component/contact-services.vue')),
    },
    data() {
        return {
        };
    },
    methods: {
    },
    mounted() {
    }

}
</script>
